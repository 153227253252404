export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.client) return;

  const { user, token } = await useAuth();

  if (!user.value) {
    token.value = null;
    return navigateTo("/login");
  }
});
